import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Dialog,
  FormControlLabel,
  FormControl,
  TextField,
  Typography,
  CircularProgress,
  makeStyles,
  colors,
  RadioGroup,
  Radio,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  active: {
    color: colors.green[600],
    fontWeight: theme.typography.fontWeightMedium,
  },
  resigned: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

function SmsForm({ customers, open, onClose, onApply, className, ...rest }) {
  const classes = useStyles();
  const [sending, setSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, setValue, watch, getValues } = useForm();
  const { message } = watch();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [formData, setFormData] = useState({});

  const [radioButtonsValue, setRadioButtonsValue] = useState('all');

  const closeConfirmDialog = () => setIsConfirmDialogOpen(false);

  const handleRadioButtonsChange = (event) => {
    setRadioButtonsValue(event.target.value);
  };

  const addParameterToMessage = (parameter) => {
    setValue('message', `${message || ''} ${parameter}`);
  };

  const onConfirmSend = async () => {
    try {
      setSending(true);
      await axios.post('/api/sms', {
        customers: formData.customers,
        parents: formData.parents,
        targets: formData.targets,
        message: formData.message,
      });
      enqueueSnackbar('SMS sended', {
        variant: 'success',
      });
      setValue('', 'message');
      setSending(false);
      onApply();
    } catch (e) {
      enqueueSnackbar(`Error ${e}`, {
        variant: 'error',
      });
      setSending(false);
    }
  };

  const onSubmit = async (data) => {
    const { message } = data;

    const selectedCustomers = (radioButtonsValue === 'all'
      ? customers
      : customers.filter((customer) => customer.due > 0)
    ).filter((customer) => customer.groups.length && customer.groups.some((group) => !!group?.group_id));

    const filteredCustomers = selectedCustomers.filter((customer) => !!customer.phone_number);

    const selectedParents = selectedCustomers
      .map((customer) =>
        customer.parents.length ? customer.parents.filter((parent) => !!parent && parent.phone_number) : []
      )
      .flat();

    let targets = [];
    let targetParents = [];
    let targetCustomers = [];

    filteredCustomers.forEach((customer) => {
      const { id, first_name: firstName, last_name: lastName, due, phone_number: phone } = customer;
      targets.push({ phone, customerId: id });
      targetCustomers.push({
        id,
        first_name: firstName,
        last_name: lastName,
        due,
        phone,
      });
    });

    selectedCustomers.forEach((customer) => {
      selectedParents.forEach(({ parent_id }) => {
        const parent =
          customer.parents && customer.parents.length && customer.parents.find((parent) => parent?.parent_id === parent_id);
        if (parent) {
          const { phone_number: phone } = parent;
          targets.push({ phone, customerId: customer.id, parentId: parent_id });
          targetParents.push({
            id: parent_id,
            phone,
            customer,
          });
        }
      });
    });

    setFormData({
      customers: targetCustomers,
      parents: targetParents,
      targets,
      message,
    });
    setIsConfirmDialogOpen(true);
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <form onSubmit={handleSubmit(onSubmit)} className={clsx(classes.root, className)} style={{ width: 500 }} {...rest}>
        <Typography align="center" className={classes.title} gutterBottom variant="h4" color="textPrimary">
          Send SMS message
        </Typography>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radioButtonsValue}
            onChange={handleRadioButtonsChange}
          >
            <FormControlLabel value="all" control={<Radio />} label="Select All" />
            <FormControlLabel value="due" control={<Radio />} label="Select All Due" />
          </RadioGroup>
        </FormControl>
        <Box mt={3}>
          <TextField
            fullWidth
            label="SMS message"
            multiline
            rows={5}
            required
            variant="outlined"
            name="message"
            defaultValue={''}
            inputRef={register}
          />
        </Box>
        <Box mt={2}>
          <Chip
            label={'First Name'}
            clickable
            onClick={() => addParameterToMessage('{customerFirstName}')}
            color="primary"
          />
          <Chip label={'Last Name'} clickable onClick={() => addParameterToMessage('{customerLastName}')} color="primary" />
          <Chip label={`Należność`} clickable onClick={() => addParameterToMessage('{due}')} />
        </Box>
        <Box mt={3}>
          <Button type="submit" variant="contained" fullWidth color="primary">
            Send SMS
          </Button>
        </Box>
      </form>
      <Backdrop className={classes.backdrop} open={sending}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={isConfirmDialogOpen}
        keepMounted
        onClose={closeConfirmDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {'Wysłanych zostanie ' + (formData?.targets?.length || 0) + ' wiadomości SMS. Czy chcesz kontynuować?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeConfirmDialog();
              onConfirmSend();
            }}
          >
            Tak
          </Button>
          <Button onClick={closeConfirmDialog}>Nie</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

SmsForm.propTypes = {
  author: PropTypes.object.isRequired,
  className: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

SmsForm.defaultProps = {
  onApply: () => {},
  onClose: () => {},
};

export default SmsForm;
