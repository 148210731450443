import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'src/utils/axios';

import { RootContext } from 'src/context/RootContext';

function ConfirmEmail() {
  const history = useHistory();
  const { id, token } = useParams();
  const { signIn, isAuthenticated } = useContext(RootContext);

  const [jwtToken, setJwtToken] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    const confirmEmail = async () => {
      try {
        const {
          data: { token: jwtToken },
        } = await axios.post('/api/auth/confirm', { id, token });

        if (!isCancelled) {
          setJwtToken(jwtToken);
        }
      } catch (e) {
        if (isAuthenticated && !isCancelled) {
          history.replace('/account-settings');
          history.go(0);
        } else {
          //Propose resend e-mail
        }
      }
    };

    confirmEmail();

    return () => {
      isCancelled = true;
    };
  }, [id, token, history, isAuthenticated]);

  useEffect(() => {
    if (jwtToken) {
      const signedIn = signIn(jwtToken, {});
      signedIn ? history.replace('/account-settings') : history.replace('/create-account');
      history.go(0);
    }
  }, [jwtToken, history, signIn]);

  return (
    <div>
      <b>Loading...</b>
    </div>
  );
}

export default ConfirmEmail;
