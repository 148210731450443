import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useFieldArray } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PERIODS } from 'src/constants/';

const SIGNS = [
  { value: 'LOWER', label: '<' },
  { value: 'LOWER_EQUAL', label: '<=' },
  { value: 'EQUAL', label: '=' },
  { value: 'HIGHER_EQUAL', label: '>=' },
  { value: 'HIGHER', label: '>' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  delete: {
    height: '75%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function PricingForm({ control, register, watch, errors, getValues }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel2');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'advancedSettings',
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const helperTextDiscountOrRise = (baseAmount = getValues('amount').amount, currentAmount) => {
    const typeOfChange = parseFloat(currentAmount) < parseFloat(baseAmount) ? 'decrease' : 'increase';
    const percentage = 100 - (currentAmount * 100) / baseAmount;
    return `${percentage}% ${typeOfChange}`;
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography className={classes.heading}>Pricing settings</Typography>
          <Typography className={classes.secondaryHeading}>{`Customer will pay ${watch('amount')} pln for each ${watch(
            'every'
          )} ${watch('period')}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                error={errors.name ? true : false}
                helperText={errors.name ? errors.name.message : null}
                label="Pricing name"
                name="name"
                variant="outlined"
                inputRef={register()}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                error={errors.amount ? true : false}
                helperText={errors.amount ? errors.amount.message : null}
                type="number"
                label="Amount"
                name="amount"
                variant="outlined"
                inputRef={register()}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                error={errors.entryFeeAmount ? true : false}
                helperText={errors.entryFeeAmount ? errors.entryFeeAmount.message : null}
                type="number"
                label="Entry Fee amount"
                name="entryFeeAmount"
                variant="outlined"
                inputRef={register()}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={errors.period ? true : false}
                helperText={errors.period ? errors.period.message : null}
                label="Period"
                name="period"
                variant="outlined"
                inputRef={register()}
                select
                SelectProps={{
                  native: true,
                }}
              >
                {PERIODS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                error={errors.every ? true : false}
                helperText={errors.every ? errors.every.message : null}
                type="number"
                label="Every"
                name="every"
                variant="outlined"
                inputRef={register()}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography className={classes.heading}>Advanced settings</Typography>
          <Typography className={classes.secondaryHeading}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {fields.map((item, index) => {
              return (
                <Grid container spacing={1}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      error={errors.action ? true : false}
                      helperText={errors.action ? errors.action.message : null}
                      label="Period"
                      name={`advancedSettings[${index}].action`}
                      variant="outlined"
                      inputRef={register()}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option key={'IN_FIRST_PERIOD'} value={'IN_FIRST_PERIOD'}>
                        {`In first ${watch('period')}`}
                      </option>
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      error={errors.diffSign ? true : false}
                      helperText={errors.diffSign ? errors.diffSign.message : null}
                      label="Sign"
                      name={`advancedSettings[${index}].diffSign`}
                      variant="outlined"
                      inputRef={register()}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {SIGNS.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      type={'number'}
                      error={errors.numberOfActivities ? true : false}
                      fullWidth
                      helperText={errors.numberOfActivities ? errors.numberOfActivities.message : null}
                      label="Number of activities"
                      name={`advancedSettings[${index}].numberOfActivities`}
                      variant="outlined"
                      inputRef={register({})}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      type={'number'}
                      error={errors.amount ? true : false}
                      fullWidth
                      label="Amount"
                      name={`advancedSettings[${index}].amount`}
                      variant="outlined"
                      defaultValue={watch('amount')}
                      helperText={helperTextDiscountOrRise(watch('amount'), watch(`advancedSettings[${index}].amount`))}
                      inputRef={register({})}
                    />
                  </Grid>

                  <Grid item md={1} xs={12}>
                    <Button
                      className={classes.delete}
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      color="secondary"
                      onClick={() => remove(index)}
                    />
                  </Grid>
                </Grid>
              );
            })}
            <Grid item md={12} xs={12}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  append();
                }}
              >
                Add pricing rule
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PricingForm;
