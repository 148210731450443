import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import axios from 'src/utils/axios';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, jssPreset, makeStyles, StylesProvider, ThemeProvider } from '@material-ui/core';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import ScrollReset from 'src/components/ScrollReset';
import Routes from 'src/Routes';

import RootComponent from 'src/context/RootContext';
import { getCurrentGradeYear, GradeYearProvider } from './context/YearContext';
const history = createBrowserHistory();

axios.defaults.headers.common['Authorization'] =
  window.localStorage.getItem('authBody') &&
  JSON.parse(window.localStorage.getItem('authBody')) !== null &&
  `Bearer ${JSON.parse(window.localStorage.getItem('authBody')).token}`;

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response.status === 400) {
      const { code } = error.response.data;
      switch (code) {
        case 'SUBSCRIPTION_INACTIVE':
          // history.push('/subscription');
          window.location.href = '/subscription';
          break;
        default:
          break;
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        'font-smoothing': 'antialiased',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
);

function App() {
  useStyles();

  const { settings } = useSettings();
  const currentGradeYear = getCurrentGradeYear();
  const [gradeYear, setGradeYear] = useState(currentGradeYear);

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <RootComponent>
            <Router history={history}>
              <SnackbarProvider maxSnack={1}>
                <ScrollReset />
                <GradeYearProvider gradeYear={gradeYear} setGradeYear={setGradeYear}>
                  <Routes />
                </GradeYearProvider>
              </SnackbarProvider>
            </Router>
          </RootComponent>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
