import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'src/utils/axios';
import { Grid, makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import GroupsList from './GroupsList';
import { useGradeYear } from 'src/context/YearContext';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

function SelectGroups({ className, selectedGroups, setSelectedGroups, ...rest }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [groups, setGroups] = useState([]);

  const { gradeYear } = useGradeYear();

  const getGroups = useCallback(() => {
    axios
      .get('/api/groups', {
        params: { gradeYear },
      })
      .then((response) => {
        if (isMountedRef.current) {
          setGroups(response.data.groups);
        }
      });
  }, [isMountedRef, gradeYear]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleSelectGroup = (customer) => () => {
    const { id: customerId } = customer;
    if (!selectedGroups.some((customer) => customer.id === customerId)) {
      setSelectedGroups((prevSelected) => [...prevSelected, customer]);
    } else {
      setSelectedGroups((prevSelected) => prevSelected.filter((customer) => customer.id !== customerId));
    }
  };

  return (
    <Grid container spacing={3} justify="space-between" className={clsx(classes.root, className)} {...rest}>
      <Grid item md={6} xs={12}>
        <GroupsList
          title="Selected groups"
          allowSearch={false}
          groups={selectedGroups}
          selectedGroups={selectedGroups}
          handleSelectGroup={handleSelectGroup}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <GroupsList
          title="All groups"
          allowSearch={true}
          groups={groups}
          selectedGroups={selectedGroups}
          handleSelectGroup={handleSelectGroup}
        />
      </Grid>
    </Grid>
  );
}

SelectGroups.propTypes = {
  className: PropTypes.string,
  onComplete: PropTypes.func,
  onBack: PropTypes.func,
};

export default SelectGroups;
