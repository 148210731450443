import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import TableResults from 'src/components/TableResults';
import {
  NumberRangeColumnFilter,
  SelectTableColumnFilter,
  includeSelectTableFilter,
  fuzzySearchFilter,
} from 'src/utils/tableFilters';

import PropTypes from 'prop-types';
import { Box, Card, Chip, Link, IconButton, SvgIcon, Typography, makeStyles, colors } from '@material-ui/core';

import { ArrowRight as ArrowRightIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  plus: {
    color: colors.green[600],
    fontWeight: theme.typography.fontWeightMedium,
  },
  minus: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium,
  },
  pending: {
    color: colors.amber[600],
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

function generateAmountColor({ row, value }, classes) {
  let amount = parseInt(value);
  let amountClass;
  if (value < 0) {
    amountClass = classes.minus;
    // amount *= -1;
  } else if (row.original.status === 'due') {
    amountClass = classes.minus;
    // amount *= -1;
  } else if (row.original.status === 'pending') {
    amountClass = classes.pending;
    // amount *= -1;
  } else {
    amountClass = classes.plus;
  }

  return <span className={amountClass}>{amount.toFixed(2)}</span>;
}

function Results({ className, loading, customers, ...rest }) {
  const history = useHistory();
  const classes = useStyles();
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'full_name',
        filter: 'fuzzyText',
        Cell: ({ row, value }) => (
          <Link color="inherit" component={RouterLink} to={`/customers/${row.original.id}`} variant="h6">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Klasa',
        accessor: 'school_class',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone_number',
      },
      {
        Header: 'Parents',
        accessor: 'parents',
        filter: fuzzySearchFilter,
        Cell: ({ value }) => (
          <Box display="flex" justifyContent="flex-start">
            {value.map(
              (parent) =>
                parent && (
                  <Box display="flex" alignItems="center" ml={1} mr={1}>
                    <div>
                      {parent.first_name} {parent.last_name}
                      <Typography variant="body2" color="textSecondary">
                        <span role="img" aria-label="phone">
                          📱
                        </span>
                        {parent.phone_number}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <span role="img" aria-label="email">
                          📧
                        </span>
                        {parent.email}
                      </Typography>
                    </div>
                  </Box>
                )
            )}
          </Box>
        ),
      },
      {
        Header: 'Groups',
        accessor: 'groups',
        Filter: SelectTableColumnFilter,
        filter: includeSelectTableFilter,
        Cell: ({ value }) => (
          <Box display="flex" justifyContent="flex-start">
            {value.map(
              (group) =>
                group && (
                  <Chip
                    size="small"
                    color="secondary"
                    label={group.name}
                    onClick={() => history.push(`/groups/${group.group_id}`)}
                    style={{ margin: '0 5px' }}
                  />
                )
            )}
          </Box>
        ),
      },
      {
        Header: 'Saldo',
        accessor: 'saldo',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
        Cell: (props) => generateAmountColor(props, classes),
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <IconButton component={RouterLink} to={`/customers/${row.original.id}`}>
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          </IconButton>
        ),
      },
    ],
    [classes, history]
  );

  const sortBy = [
    {
      id: 'full_name',
      desc: false,
    },
  ];

  return (
    <Card {...rest}>
      <TableResults loading={loading} data={customers} columns={columns} sortBy={sortBy} />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
