import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import axios from 'src/utils/axios';
import { FormControl, InputLabel, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import { useGradeYear } from 'src/context/YearContext';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

function GroupSelector({ className, selectedGroup, setSelectedGroup, ...rest }) {
  const isMountedRef = useIsMountedRef();
  const [groups, setGroups] = useState([]);

  const { gradeYear } = useGradeYear();

  const getGroups = useCallback(() => {
    axios
      .get('/api/groups', {
        params: { gradeYear },
      })
      .then((response) => {
        if (isMountedRef.current) {
          setGroups(response.data.groups);
        }
      });
  }, [isMountedRef, gradeYear]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleSelectGroup = (event) => {
    setSelectedGroup(event.target.value);
  };
  const formattedGroups = groups.map((group) => {
    return { label: group.name, id: group.id };
  });

  return (
    <FormControl variant="outlined" style={{ width: 200, marginRight: 20, marginLeft: 20 }}>
      <InputLabel id="demo-simple-select-outlined-label">Select Group</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={selectedGroup}
        onChange={handleSelectGroup}
        label="Select Group"
      >
        <MenuItem value={''}>
          <em>None</em>
        </MenuItem>
        {formattedGroups.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

GroupSelector.propTypes = {
  className: PropTypes.string,
  onComplete: PropTypes.func,
  onBack: PropTypes.func,
};

export default GroupSelector;
