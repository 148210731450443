/* eslint-disable max-len */
import React from 'react';
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ArrowUp as SortAsc, ArrowDown as SortDesc } from 'react-feather';

function MiniTable({ data, columns }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      defaultCanFilter: false,
      disableMultiSort: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const headerTable = (
    <TableHead>
      {// Loop over the header rows
      headerGroups.map((headerGroup) => (
        // Apply the header row props
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {// Loop over the headers in each row
          headerGroup.headers.map((column) => (
            // Apply the header cell props
            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
              <Box display={'flex'}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <SvgIcon fontSize="small">
                        <SortDesc />
                      </SvgIcon>
                    ) : (
                      <SvgIcon fontSize="small">
                        <SortAsc />
                      </SvgIcon>
                    )
                  ) : (
                    ''
                  )}
                </span>
              </Box>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
  return (
    <div>
      <PerfectScrollbar>
        <Box minWidth={1200}>
          <Table {...getTableProps()}>
            {headerTable}
            <TableBody {...getTableBodyProps()}>
              {// Loop over the table rows
              page.length > 0 &&
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <TableRow hover {...row.getRowProps()} style={{ background: row.original?.color_code ?? undefined }}>
                      {// Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </div>
  );
}

export default MiniTable;
