import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'src/utils/axios';
import {
  ButtonBase,
  Box,
  Popover,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
// import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// import { RootContext } from 'src/context/RootContext';
import { loadStripe } from '@stripe/stripe-js';
import { ShoppingCart as MessageIcon } from 'react-feather';
const stripePromise = loadStripe('pk_live_Je3ncDnUP7gxR2lJQzt1xNi2');

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

function SmsPoints() {
  const classes = useStyles();
  // const { authBody } = useContext(RootContext);
  // const { company_id: companyId, email } = authBody;

  // const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const ref = useRef(null);

  const [isOpen, setOpen] = useState(false);
  const [smsPoints, setSmsPoints] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSmsPoints = useCallback(() => {
    axios.get('/api/company').then((response) => {
      if (isMountedRef.current) {
        setSmsPoints(response.data.sms_points);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getSmsPoints();
  }, [getSmsPoints]);

  // const handlePaddleBuySmsPoints = () => {
  //   const Paddle = window.Paddle;
  //   Paddle.Checkout.open({
  //     product: 636825,
  //     email,
  //     passthrough: `${companyId}`,
  //     successCallback: (data, err) => {
  //       const { product } = data;
  //       const { quantity } = product;

  //       enqueueSnackbar('SMS Points added', {
  //         variant: 'success',
  //       });
  //       setSmsPoints(parseInt(smsPoints) + parseInt(quantity * 100));
  //     },
  //   });
  // };

  const handleStripeBuySmsPoints = async (productId) => {
    const stripe = await stripePromise;
    const response = await axios.post('api/stripe/create-session', { productId });
    const session = response.data;
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return (
    <>
      <Tooltip title="Buy SMS points">
        <Typography variant="h6" color="inherit" component={ButtonBase} onClick={handleOpen} ref={ref}>
          {`${smsPoints} SMS`}
        </Typography>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h4" color="textPrimary">
            Recharge account
          </Typography>
        </Box>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button onClick={() => handleStripeBuySmsPoints('price_1HqzZ6F3JCcFVJKYB8g2YLd2')}>
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary="+ 100 SMS" secondary="PLN 20 z VAT" />
          </ListItem>
          <ListItem button onClick={() => handleStripeBuySmsPoints('price_1HqzYAF3JCcFVJKYcKLPxEnO')}>
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary="+ 1000 SMS" secondary="PLN 169 z VAT" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
export default SmsPoints;
