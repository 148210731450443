import React from 'react';
import { getCurrentGradeYear, useGradeYear } from 'src/context/YearContext';
import { Select, MenuItem, ListItemText } from '@material-ui/core';

export const GradeYearSelect = () => {
  const { gradeYear, setGradeYear } = useGradeYear();

  const ALL_BEFORE_GRADE_YEAR_UPDATE = 'ALL_BEFORE_GRADE_YEAR_UPDATE';

  const selectGradeYear = (e) => {
    if (e.target.value === ALL_BEFORE_GRADE_YEAR_UPDATE) {
      setGradeYear('');
    } else {
      setGradeYear(e.target.value);
    }
  };

  return (
    <div>
      <ListItemText primary="Choice of semester" />
      <Select
        style={{ paddingInline: 10, display: 'flex' }}
        onChange={selectGradeYear}
        defaultValue={getCurrentGradeYear()}
        value={gradeYear === '' ? ALL_BEFORE_GRADE_YEAR_UPDATE : gradeYear}
      >
        <MenuItem value={'2024/2025'}>2024/2025</MenuItem>
        <MenuItem value={'2023/2024'}>2023/2024</MenuItem>

        <MenuItem value={ALL_BEFORE_GRADE_YEAR_UPDATE}>starsze</MenuItem>
      </Select>
    </div>
  );
};
