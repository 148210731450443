import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  InputBase,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  rootInput: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

function GroupList({ title, allowSearch, groups, selectedGroups, handleSelectGroup }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const filters = {};

  const applyPagination = (groups, page, limit) => {
    return groups.slice(page * limit, page * limit + limit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const applyFilters = (groups, query, filters) => {
    return groups.filter((group) => {
      let matches = true;

      if (query) {
        const properties = ['name', 'city'];
        let containsQuery = false;

        properties.forEach((property) => {
          if (group[property] && group[property].toLowerCase().includes(query.toLowerCase())) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
      }

      return matches;
    });
  };

  const filteredGroups = applyFilters(groups, query, filters);
  const paginatedGroups = applyPagination(filteredGroups, page, limit);

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title={title} />
      {allowSearch && (
        <div>
          <Divider className={classes.divider} />
          <div className={classes.rootInput}>
            <InputBase
              className={classes.input}
              placeholder="Search groups"
              inputProps={{ 'aria-label': 'search groups' }}
              onChange={handleQueryChange}
              value={query}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </div>
        </div>
      )}
      <Divider className={classes.divider} />
      <PerfectScrollbar>
        <List className={classes.root}>
          {paginatedGroups.map((group) => {
            const { id, name } = group;
            return (
              <ListItem key={id} dense button onClick={handleSelectGroup(group)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedGroups.some((group) => group.id === id)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText id={id} primary={`${name}`} />
              </ListItem>
            );
          })}
        </List>
      </PerfectScrollbar>

      <TablePagination
        component="div"
        count={filteredGroups.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

GroupList.propTypes = {
  title: PropTypes.string,
  allowSearch: PropTypes.bool,
  groups: PropTypes.array,
  selectedGroups: PropTypes.array,
  handleSelectGroup: PropTypes.func,
};

export default GroupList;
