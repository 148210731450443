/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography, makeStyles } from '@material-ui/core';
import {
  PieChart as PieChartIcon,
  User as UserIcon,
  Users as UsersIcon,
  UserCheck as RegistersIcon,
  CreditCard as PaymentIcon,
  Archive as ArchiveIcon,
} from 'react-feather';
// import PaymentIcon from '@material-ui/icons/Payment';
import { RootContext } from 'src/context/RootContext';
import Logo from 'src/components/Logo';
import Can from 'src/rbac/Can';
import NavItem from './NavItem';
import { GradeYearSelect } from 'src/components/GradeYearSelect';

const navConfig = [
  {
    subheader: 'CRM',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/dashboard',
        permission: 'menu:dashboard',
      },
      {
        title: 'Customers',
        icon: UserIcon,
        href: '/customers',
        permission: 'menu:customers',
      },
      {
        title: 'Groups',
        icon: UsersIcon,
        href: '/groups',
        permission: 'menu:groups',
      },
      {
        title: 'Camps',
        icon: ArchiveIcon,
        href: '/camps',
        permission: 'menu:camps',
      },
      {
        title: 'Registrations',
        icon: RegistersIcon,
        href: '/registrations',
        permission: 'menu:registrations',
      },
      {
        title: 'Payments',
        icon: PaymentIcon,
        href: '/payments',
        permission: 'menu:payments',
      },
    ],
  },
];

function renderNavItems({ items, ...rest }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();

  const {
    authBody: { first_name: firstName, last_name: lastName, company_name: companyName, company_logo: companyLogo, role },
  } = useContext(RootContext);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/account">
              <Avatar alt="User" className={classes.avatar} src={companyLogo} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {`${firstName} ${lastName}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {companyName}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="row" justifyContent="center" paddingY={1}>
          <GradeYearSelect />
        </Box>
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items.filter((item) => Can({ role, perform: item.permission })),
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Typography variant="subtitle1" color="secondary">
              <a href="mailto:gruplo@gruplo.app?subject=Need help with Gruplo">gruplo@gruplo.app</a>
            </Typography>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
