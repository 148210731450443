const user = {
  // static: ['menu:groups', 'groups:list', 'group:tab:details', 'group:tab:timesheet'],
  static: [],
  dynamic: {
    'group:view': ({ userId, teachers }) => {
      if (!userId) return false;
      if (teachers.length === 0) return true;

      return teachers.find(({ teacher_id: teacherId }) => teacherId === userId) ? true : false;
    },
  },
};

const superuser = {
  static: [
    ...user.static,
    'menu:groups',
    'groups:list',
    'group:tab:details',
    'group:tab:timesheet',
    'menu:dashboard',
    'menu:customers',
    'menu:payments',
    'menu:camps',
    'camps:list',
    'camps:tab:details',
    'camps:tab:timesheet',
    'customers:list',
    'customers:create',
    'customers:view',
    'group:view',
    'group:tab:students',
    'groups:create',
    'groups:edit',
    'camps:view',
    'camps:tab:students',
    'camps:create',
    'camps:edit',
    'payments:list',
    'sms',
  ],
};

const admin = {
  static: [
    ...superuser.static,
    'menu:payments',
    'payments:list',
    'payments:import',
    'company:settings',
    'menu:registrations',
    'registrations:list',
    'groups:import',
    'camps:import',
    'customers:import',
  ],
};

const owner = {
  static: [...admin.static],
};

const rules = {
  // visitor: {
  //   static: ['posts:list', 'home-page:visit'],
  // },
  // writer: {
  //   static: ['posts:list', 'posts:create', 'users:getSelf', 'home-page:visit', 'dashboard-page:visit'],
  //   dynamic: {
  //     'posts:edit': ({ userId, postOwnerId }) => {
  //       if (!userId || !postOwnerId) return false;
  //       return userId === postOwnerId;
  //     },
  //   },
  // },
  user,
  superuser,
  admin,
  owner,
};

export default rules;
