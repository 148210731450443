import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RootContext } from 'src/context/RootContext';

function GestRoute({ component: Component, render, ...rest }) {
  const { isAuthenticated } = useContext(RootContext);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return render ? render({ ...rest }) : <Component {...rest} />;
}

GestRoute.propTypes = {
  component: PropTypes.any,
  render: PropTypes.func,
};

export default GestRoute;
