import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RootContext } from 'src/context/RootContext';
import Can from 'src/rbac/Can';

function AuthRoute({ component: Component, render, perform, path, ...rest }) {
  const {
    isAuthenticated,
    isSubscriptionActive,
    authBody: { role },
  } = useContext(RootContext);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!isSubscriptionActive && path !== '/subscription') {
    return <Redirect to="/subscription" />;
  }

  if (perform && !Can({ role, perform })) {
    return <Redirect to="/login" />;
  }

  return render ? render({ ...rest }) : <Route component={Component} {...rest} />;
  // <Component {...rest} />;
}

AuthRoute.propTypes = {
  component: PropTypes.any,
  render: PropTypes.func,
};

export default AuthRoute;
