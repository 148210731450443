import React, { useEffect, useState } from 'react';
import axios from 'src/utils/axios';
import jwtDecode from 'jwt-decode';
import { isValid, isBefore, toDate } from 'date-fns';

export const RootContext = React.createContext();

export default ({ children }) => {
  const prevAuth = JSON.parse(window.localStorage.getItem('auth')) || false;
  const prevAuthBody = JSON.parse(window.localStorage.getItem('authBody')) || {};

  const [isAuthenticated, setIsAuthenticated] = useState(prevAuth);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(prevAuthBody.status || false);
  const [authBody, setAuthBody] = useState(prevAuthBody);

  useEffect(() => {
    window.localStorage.setItem('auth', isAuthenticated);
    window.localStorage.setItem('authBody', JSON.stringify(authBody));
    const { status = '' } = authBody;
    status
      ? setIsSubscriptionActive(status.includes('trialing') || status.includes('active'))
      : setIsSubscriptionActive(false);
  }, [isAuthenticated, authBody]);

  const signIn = (token, user) => {
    if (checkTokenValidity(token)) {
      setIsAuthenticated(true);
      setAuthBody({ token, ...jwtDecode(token), company_logo: user && user.company_logo });
      return true;
    } else {
      return false;
    }
  };

  const signOut = () => {
    setIsAuthenticated(false);
    setAuthBody({});
  };

  const refreshSubscriptionStatus = async () => {
    const { data } = await axios.get('/api/company');
    const { status = '' } = data;
    status
      ? setIsSubscriptionActive(status.includes('trialing') || status.includes('active'))
      : setIsSubscriptionActive(false);
    window.localStorage.setItem('authBody', JSON.stringify({ ...authBody, status }));
  };

  const checkTokenValidity = (token) => {
    try {
      if (!token) {
        return false;
      }

      let expToken = jwtDecode(token).exp;
      const expMoment = toDate(expToken * 1000);

      return isValid(expMoment) && isBefore(new Date(), expMoment);
    } catch (e) {
      return false;
    }
  };

  const defaultContext = {
    isAuthenticated,
    isSubscriptionActive,
    refreshSubscriptionStatus,
    signIn,
    signOut,
    authBody,
  };

  return <RootContext.Provider value={defaultContext}>{children}</RootContext.Provider>;
};
