import React, { createContext, useContext } from 'react';

export const getCurrentGradeYear = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const JULY = 7;
  const currentYear = currentDate.getFullYear();
  if (currentMonth >= JULY) {
    return `${currentYear}/${currentYear + 1}`;
  }
  return `${currentYear - 1}/${currentYear}`;
};

export const GradeYearContext = createContext({ gradeYear: getCurrentGradeYear(), setGradeYear: () => {} });

export const GradeYearProvider = ({ children, gradeYear, setGradeYear }) => {
  return <GradeYearContext.Provider value={{ gradeYear, setGradeYear }}>{children}</GradeYearContext.Provider>;
};

export const useGradeYear = () => {
  return useContext(GradeYearContext);
};
