import React from 'react';
import { Select, MenuItem, TextField, SvgIcon } from '@material-ui/core';
import matchSorter from 'match-sorter';

import { Check, Close } from '@material-ui/icons';

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      style={{ height: 40, minWidth: 120 }}
      variant="outlined"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <MenuItem value="">All</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

function SelectTableColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows
      .filter((row) => row.values[id] && row.values[id].length > 0)
      .forEach((row) => {
        row.values[id].filter((entry) => entry && entry.name).forEach((entry) => options.add(entry.name));
      });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      style={{ height: 40, minWidth: 120 }}
      variant="outlined"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <MenuItem value="">All</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

function includeSelectTableFilter(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    let found = false;
    rowValue.forEach((object) => (found = found ? true : object && object.name === filterValue ? true : false));
    return found;
  });
}

function fuzzySearchFilter(rows, ids, filterValue) {
  const nestedIds = ids.filter((id) => Array.isArray(rows[0].values[id]));
  return matchSorter(rows, filterValue, {
    keys: [
      ...ids.map((id) => (row) => row.values[id]),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.name)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.full_name)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.first_name)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.last_name)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.email)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.phone)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.phone_number)),
    ],
  });
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </div>
  );
}
const minDate = (dates) => new Date(Math.min(...dates));
const maxDate = (dates) => new Date(Math.max(...dates));

function DateTimeRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
  //const [min, max] =
  React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows
      .filter((row) => row.values[id])
      .forEach((row) => {
        min = minDate(row.values[id]);
        max = maxDate(row.values[id]);
      });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <TextField
        variant="outlined"
        type="datetime-local"
        size="small"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? new Date(val) : undefined, old[1]]);
        }}
        style={{
          marginRight: '0.5rem',
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      to
      <TextField
        variant="outlined"
        size="small"
        type="datetime-local"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? new Date(val) : undefined]);
        }}
        style={{
          marginLeft: '0.5rem',
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}

function dateTimeFilter(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = new Date(row.values[id]);
    if (filterValue[0] !== undefined && filterValue[1] !== undefined) {
      return rowValue >= filterValue[0] && rowValue <= filterValue[1];
    } else if (filterValue[0] !== undefined) {
      return (rowValue) => filterValue[0];
    } else {
      return rowValue <= filterValue[1];
    }
  });
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <TextField
        variant="outlined"
        size="small"
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '120px',
          marginRight: '0.5rem',
        }}
      />
      to
      <TextField
        variant="outlined"
        size="small"
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '120px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  );
}
function BooleanColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows

  // Render a multi-select box
  return (
    <Select
      style={{ height: 40, minWidth: 120 }}
      variant="outlined"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value);
      }}
    >
      <MenuItem value="">All</MenuItem>
      <MenuItem value={'Y'}>
        <SvgIcon fontSize="small">
          <Check />
        </SvgIcon>
      </MenuItem>
      <MenuItem value={'F'}>
        <SvgIcon fontSize="small">
          <Close />
        </SvgIcon>
      </MenuItem>
    </Select>
  );
}

export {
  SelectColumnFilter,
  SelectTableColumnFilter,
  SliderColumnFilter,
  DateTimeRangeColumnFilter,
  NumberRangeColumnFilter,
  dateTimeFilter,
  includeSelectTableFilter,
  fuzzySearchFilter,
  BooleanColumnFilter,
};
