import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 128,
  },
}));

function Logo(props) {
  const classes = useStyles();
  return <img alt="Logo" className={classes.logo} src="/images/logos/logo.png" {...props} />;
}

export default Logo;
