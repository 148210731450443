export const getHiddenColumnsLocalStorage = () => {
  const json = localStorage.getItem(window.location.pathname + '-hidden-columns');
  if (json) {
    return JSON.parse(json);
  }
  return [];
};

const setHiddenColumns = (columnIds) => {
  localStorage.setItem(window.location.pathname + '-hidden-columns', JSON.stringify(columnIds));
};

const isHidden = (column) => {
  return getHiddenColumnsLocalStorage().includes(column.id);
};

export const toggleHiddenLocalStorage = (column) => {
  const hiddenColumns = getHiddenColumnsLocalStorage();
  if (isHidden(column)) {
    const newHiddenColumns = hiddenColumns.filter((columnId) => columnId !== column.id);
    setHiddenColumns(newHiddenColumns);
  } else {
    setHiddenColumns([...hiddenColumns, column.id]);
  }
};
