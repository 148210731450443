import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import axios from 'src/utils/axios';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Header from './Header';
import Results from './Results';
import { useGradeYear } from 'src/context/YearContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function CustomerListView() {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);

  const { gradeYear } = useGradeYear();

  const getCustomers = useCallback(() => {
    axios
      .get('/api/customers', {
        params: { gradeYear },
      })
      .then((response) => {
        if (isMountedRef.current) {
          setCustomers(response.data.customers);
          setLoading(false);
        }
      });
  }, [isMountedRef, gradeYear]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <Page className={classes.root} title="Customer List">
      <Container maxWidth={false}>
        <Header customers={customers} />
        {customers && (
          <Box mt={3}>
            <Results loading={loading} customers={customers} setCustomers={setCustomers} />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default CustomerListView;
