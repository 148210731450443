/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from 'react-table';
import matchSorter from 'match-sorter';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { jsonToCSV } from 'react-papaparse';
import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  MenuItem,
  Input,
  ListItemIcon,
  ListItemText,
  Select,
  SvgIcon,
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  ArrowUp as SortAsc,
  ArrowDown as SortDesc,
  ChevronLeft as PaginateLeft,
  ChevronsLeft as PaginateMaxLeft,
  ChevronRight as PaginateRight,
  ChevronsRight as PaginateMaxRight,
  MoreVertical as MoreVertIcon,
  FileMinus as File,
  Download as DownloadIcon,
} from 'react-feather';
import GroupSelector from 'src/views/registrations/GroupSelector/GroupSelector';
import AlertDialog from './AlertDialog';
import MiniTable from './MiniTable';
import { CompactPicker } from 'react-color';
import { getHiddenColumnsLocalStorage, toggleHiddenLocalStorage } from 'src/utils/tableHiddenColumns';

const useStyles = makeStyles((theme) => ({
  root: {},
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  pagination: {
    marginLeft: '15px',
    padding: '15px',
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  queryField: {
    width: 500,
  },
  categoryField: {
    flexBasis: 200,
  },
  availabilityField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200,
  },
  stockField: {
    marginLeft: theme.spacing(2),
  },
  shippableField: {
    marginLeft: theme.spacing(2),
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0,
  },
  image: {
    height: 68,
    width: 68,
  },
}));

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Box display="flex" alignItems="center">
      <TextField
        className={classes.queryField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        variant="outlined"
        placeholder={`Search`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </Box>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon fontSize="small" color="action">
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        ),
      }}
      size="small"
      style={{ minWidth: 120 }}
      variant="outlined"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
    />
  );
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const globalFuzzyTextFilterFn = (rows, ids, filterValue) => {
  const nestedIds = ids.filter((id) => rows[0] && Array.isArray(rows[0].values[id]));
  return matchSorter(rows, filterValue, {
    keys: [
      ...ids.map((id) => (row) => row.values[id]),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.name)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.full_name)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.first_name)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.last_name)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.email)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.phone)),
      ...nestedIds.map((id) => (row) => row.values[id].filter((i) => i).map((i) => i.phone_number)),
    ],
  });
};

// Let the table remove the filter if the string is empty
globalFuzzyTextFilterFn.autoRemove = (val) => !val;

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox ref={resolvedRef} {...rest} />;
});

const NoData = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    style={{ width: '100%', minHeight: 200 }}
  >
    <SvgIcon fontSize="large">
      <File />
    </SvgIcon>
    <Typography>No data</Typography>
  </Box>
);

const Loading = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    style={{ width: '100%', minHeight: 200 }}
  >
    <CircularProgress color="secondary" size={80} />
    <Typography>Loading data..</Typography>
  </Box>
);

const TableResults = ({
  loading,
  data,
  columns,
  hiddenColumns,
  sortBy,
  rowSelection = false,
  groupSelector = false,
  colorCode = false,
  pageSizeDefault = 5,
  onlyTable = false,
  skipPageReset,
  skipFilterReset,
  skipSortReset,
  submitHandler,
  updateRegistrationsColor,
  children,
}) => {
  const classes = useStyles();
  const [exportData, setExportData] = useState([]);

  const TableMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleExportToCsv = () => {
      const csvData = jsonToCSV(exportData);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'test.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setAnchorEl(null);
    };
    return (
      <div>
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleExportToCsv}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            Export to CSV
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const MultipleSelectVisibleColumns = React.memo(({ columns }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * columns.length + 1 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const handleChange = (event) => {
      event.stopPropagation();
      const columnId = event.target.value[1];
      const column = columns.find((col) => col.id === columnId);
      column.toggleHidden();
      toggleHiddenLocalStorage(column);
    };

    return (
      <Select
        variant="outlined"
        multiple
        value={['Visible columns']}
        onChange={(e) => handleChange(e)}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {columns.map((column) => (
          <MenuItem key={column.id} value={column.id}>
            <Checkbox checked={column.getToggleHiddenProps().checked} />
            <ListItemText primary={column.Header} />
          </MenuItem>
        ))}
      </Select>
    );
  });

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const [selectedGroup, setSelectedGroup] = useState();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    rows,
    allColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      defaultCanFilter: false,
      filterTypes,
      globalFilter: globalFuzzyTextFilterFn,
      initialState: {
        pageSize: onlyTable ? 999999 : pageSizeDefault,
        hiddenColumns: [...hiddenColumns, ...getHiddenColumnsLocalStorage()],
        sortBy,
      },
      autoResetPage: !skipPageReset,
      autoResetFilters: !skipFilterReset,
      autoResetSortBy: !skipSortReset,
      autoResetHiddenColumns: false,
      disableMultiSort: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      rowSelection &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );
  useEffect(() => {
    const convertedValues = rows.map((row) => row.values);
    setExportData(convertedValues);
    setGlobalFilter(state.globalFilter);
  }, [rows, setGlobalFilter, state.globalFilter]);

  const headerTable = (
    <TableHead>
      {// Loop over the header rows
      headerGroups.map((headerGroup) => (
        // Apply the header row props
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {// Loop over the headers in each row
          headerGroup.headers.map((column) => (
            // Apply the header cell props
            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
              <Box display={'flex'}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <SvgIcon fontSize="small">
                        <SortDesc />
                      </SvgIcon>
                    ) : (
                      <SvgIcon fontSize="small">
                        <SortAsc />
                      </SvgIcon>
                    )
                  ) : (
                    ''
                  )}
                </span>
              </Box>
              <div>{column.canFilter ? column.render('Filter') : null}</div>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
  const [color, setColor] = useState('#FFF');
  const handleColorChange = (value) => {
    setColor(value.hex);
  };

  const renderChildren = (selectedIds) => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        selectedIds,
      });
    });
  };

  return (
    <div>
      {!onlyTable && (
        <Box p={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <Box display="flex" alignItems="center">
              {colorCode && (
                <AlertDialog
                  style={{ marginRight: '20px' }}
                  title="Choose Color"
                  innerText={'Dou you want to change colors for selected registrations?'}
                  disabled={selectedFlatRows.length === 0}
                  onAgree={() => {
                    updateRegistrationsColor(
                      selectedFlatRows.map((row) => row.original.id),
                      color === '' ? null : color
                    );
                  }}
                  innerController={
                    <Box height={'100px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <CompactPicker color={color} onChangeComplete={handleColorChange} />
                    </Box>
                  }
                />
              )}
              {groupSelector && (
                <>
                  <AlertDialog
                    title="Add to group"
                    innerText={'Dou you want to create customers for selected group?'}
                    disabled={selectedFlatRows.length === 0 || !selectedGroup}
                    onAgree={() => {
                      submitHandler(
                        selectedFlatRows.map((row) => row.original.id),
                        selectedGroup
                      );
                    }}
                    alertDialogProps={{ minWidth: 1200, maxWidth: 1200 }}
                    innerController={
                      <Box style={{ display: 'flex', flexDirection: 'column', minWidth: 1200 }}>
                        <MiniTable
                          columns={[
                            {
                              Header: 'First Name',
                              accessor: 'original.payload.firstName',
                            },
                            {
                              Header: 'Last Name',
                              accessor: 'original.payload.lastName',
                            },
                            {
                              Header: 'City',
                              accessor: 'original.payload.city',
                            },
                            {
                              Header: 'School Number',
                              accessor: 'original.payload.schoolNumber',
                            },
                          ]}
                          data={selectedFlatRows}
                        />
                      </Box>
                    }
                  />
                  <GroupSelector selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
                </>
              )}
              {children && renderChildren(selectedFlatRows.map((row) => row.original.id))}
              <MultipleSelectVisibleColumns columns={allColumns} />

              <TableMenu />
            </Box>
          </Box>
        </Box>
      )}

      <PerfectScrollbar>
        <Box minWidth={1200}>
          <Table {...getTableProps()}>
            {headerTable}
            <TableBody {...getTableBodyProps()}>
              {// Loop over the table rows
              page.length > 0 &&
                page.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <TableRow hover {...row.getRowProps()} style={{ background: row.original?.color_code ?? undefined }}>
                      {// Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          {!loading && data.length === 0 && <NoData />}
          {loading && <Loading />}
        </Box>
      </PerfectScrollbar>
      {!onlyTable && (
        <div className={classes.pagination}>
          <Select
            variant="outlined"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize}>
                Show {pageSize}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <SvgIcon>
              <PaginateMaxLeft />
            </SvgIcon>
          </Button>{' '}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <SvgIcon>
              <PaginateLeft />
            </SvgIcon>
          </Button>{' '}
          <Typography variant="body2" style={{ display: 'inline-block' }}>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </Typography>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            <SvgIcon>
              <PaginateRight />
            </SvgIcon>
          </Button>{' '}
          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <SvgIcon>
              <PaginateMaxRight />
            </SvgIcon>
          </Button>{' '}
          <Typography variant="body2" style={{ display: 'inline-block' }}>
            {' '}
            Go to page:{' '}
            <TextField
              variant="outlined"
              type="number"
              size="small"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '75px', verticalAlign: 'baseline' }}
            />
          </Typography>
          <Typography variant="body2" style={{ marginLeft: '10px', display: 'inline-block' }}>
            {'Number of rows: ' + rows.length}
          </Typography>
        </div>
      )}
    </div>
  );
};

TableResults.propTypes = {
  hiddenColumns: PropTypes.array,
  sortBy: PropTypes.array,
};

TableResults.defaultProps = {
  hiddenColumns: [],
  sortBy: [],
};

export default TableResults;
