import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useForm, useFieldArray } from 'react-hook-form';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { Box, Button, Card, CardHeader, CardContent, Divider, Grid, TextField, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PricingFrom from 'src/components/PricingForm';
import SelectGroups from './SelectGroups';
import { RELATIONSHIPS } from 'src/constants/';

const useStyles = makeStyles(() => ({
  root: {},
  delete: {
    height: '100%',
  },
}));

function CustomerCreateForm({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { register, control, handleSubmit, errors, watch, setValue, reset } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parentsAndGuardians',
  });
  const [createNewPricing, setCreateNewPricing] = useState(false);
  const [feesDetails, setFeesDetails] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    if (!state) {
      return;
    }

    const { id: registrationId, payload } = state;

    const { firstName, lastName, email, phone: phoneNumber, schoolClass, parentsAndGuardians } = payload;

    reset({
      firstName,
      lastName,
      email,
      phoneNumber,
      schoolClass,
      registrationId,
    });

    register('registrationId');
    setValue('registrationId', registrationId);

    append(parentsAndGuardians[0]);
  }, []);

  const getFeesDetails = useCallback(() => {
    axios.get('/api/fees-definitions').then((response) => {
      if (isMountedRef.current) {
        setFeesDetails(response.data);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getFeesDetails();
  }, [getFeesDetails]);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('/api/customers', { ...data, selectedGroups });
      const { id: customerId } = response.data;
      history.push(`/customers/${customerId}`);
      enqueueSnackbar('Customer created', {
        variant: 'success',
        action: (
          <Button component={RouterLink} to={`/customers/${customerId}`}>
            View
          </Button>
        ),
      });
    } catch (e) {
      enqueueSnackbar(e.toString(), {
        variant: 'error',
      });
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <form className={clsx(classes.root, className)} onSubmit={handleSubmit(onSubmit)} {...rest}>
        <Box mt={3} mb={1} />
        <Card>
          <CardHeader title="Personal Data" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  error={errors.firstName ? true : false}
                  fullWidth
                  helperText={errors.firstName ? errors.firstName.message : null}
                  label="First name"
                  name="firstName"
                  required
                  variant="outlined"
                  inputRef={register({
                    required: 'First Name is required',
                  })}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={errors.lastName ? true : false}
                  fullWidth
                  helperText={errors.lastName ? errors.lastName.message : null}
                  label="Last name"
                  name="lastName"
                  required
                  variant="outlined"
                  inputRef={register({
                    required: 'Last Name is required',
                  })}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={errors.email ? true : false}
                  fullWidth
                  helperText={errors.email ? errors.email.message : null}
                  label="Email address"
                  name="email"
                  variant="outlined"
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={errors.phoneNumber ? true : false}
                  fullWidth
                  helperText={errors.phoneNumber ? errors.phoneNumber.message : null}
                  label="Phone"
                  name="phoneNumber"
                  variant="outlined"
                  inputRef={register()}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={errors.schoolClass ? true : false}
                  fullWidth
                  helperText={errors.schoolClass ? errors.schoolClass.message : null}
                  label="Klasa"
                  name="schoolClass"
                  variant="outlined"
                  inputRef={register()}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box mt={3} mb={1} />
        <Card>
          <CardHeader title="Parents and Guardian" />
          <Divider />
          <CardContent>
            {fields.map((item, index) => {
              return (
                <Box mt={1} mb={2}>
                  <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                      <Autocomplete
                        options={RELATIONSHIPS}
                        getOptionLabel={(option) => option.key}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.relationship ? true : false}
                            fullWidth
                            helperText={errors.relationship ? errors.relationship.message : null}
                            label="Relationship"
                            variant="outlined"
                            name={`parentsAndGuardians[${index}].relationship`}
                            // required
                            defaultValue={'unknown'}
                            inputRef={register({
                              // required: 'Relationship is required',
                            })}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        error={errors.firstName ? true : false}
                        fullWidth
                        helperText={errors.firstName ? errors.firstName.message : null}
                        label="First name"
                        name={`parentsAndGuardians[${index}].firstName`}
                        // required
                        variant="outlined"
                        defaultValue={item.firstName}
                        inputRef={register({
                          // required: 'First Name is required',
                        })}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        error={errors.lastName ? true : false}
                        fullWidth
                        helperText={errors.lastName ? errors.lastName.message : null}
                        label="Last name"
                        name={`parentsAndGuardians[${index}].lastName`}
                        // required
                        variant="outlined"
                        defaultValue={item.lastName}
                        inputRef={register({
                          // required: 'Last Name is required',
                        })}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        error={errors.email ? true : false}
                        fullWidth
                        helperText={errors.email ? errors.email.message : null}
                        label="Email address"
                        name={`parentsAndGuardians[${index}].email`}
                        variant="outlined"
                        defaultValue={item.email}
                        inputRef={register()}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        error={errors.phoneNumber ? true : false}
                        fullWidth
                        helperText={errors.phoneNumber ? errors.phoneNumber.message : null}
                        label="Phone"
                        name={`parentsAndGuardians[${index}].phoneNumber`}
                        variant="outlined"
                        defaultValue={item.phone}
                        inputRef={register()}
                      />
                    </Grid>
                    <Grid item md={1} xs={12}>
                      <Button
                        className={classes.delete}
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={() => remove(index)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
            <Box mt={1}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  append();
                }}
              >
                Add parent or guardian
              </Button>
            </Box>
          </CardContent>
        </Card>

        <Box mt={3} mb={1}>
          <Card>
            <CardHeader title="Pricing" />
            <Divider />
            <CardContent>
              {!createNewPricing && (
                <TextField
                  fullWidth
                  error={errors.feeDefinition ? true : false}
                  variant="outlined"
                  inputRef={register()}
                  required
                  select
                  SelectProps={{
                    native: true,
                  }}
                  helperText="Please select method of settlement for customer"
                  label="Pricing"
                  name="feeDefinitionId"
                >
                  <option key="" value="" />
                  {feesDetails.map((option) => (
                    <option key={option.id} value={option.id}>
                      {`${option.name} - Pay ${option.amount} for each ${option.period_repeat} ${option.period}`}
                    </option>
                  ))}
                </TextField>
              )}
              <Box mb={2}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setCreateNewPricing(!createNewPricing);
                  }}
                >
                  {createNewPricing ? 'Select from existing' : 'Create new pricing'}
                </Button>
              </Box>
              {createNewPricing && <PricingFrom watch={watch} register={register} errors={errors} />}
            </CardContent>
          </Card>
        </Box>

        <Box mt={3} mb={1}>
          <Card>
            <CardHeader title="Groups" />
            <Divider />
            <CardContent>
              <SelectGroups selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} />
            </CardContent>
          </Card>
        </Box>

        <Box mt={2}>
          <Button variant="contained" color="secondary" type="submit" fullWidth>
            Create Customer
          </Button>
        </Box>
      </form>
    </div>
  );
}

CustomerCreateForm.propTypes = {
  className: PropTypes.string,
};

export default CustomerCreateForm;
