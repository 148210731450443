import React, { useRef, useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { RootContext } from 'src/context/RootContext';
import Can from 'src/rbac/Can';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const {
    authBody: { first_name: firstName, last_name: lastName, company_logo: companyLogo, role },
  } = useContext(RootContext);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      window.localStorage.setItem('auth', false);
      window.localStorage.setItem('authBody', JSON.stringify({}));
      history.push('/');
      history.go(0);
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Avatar alt="User" className={classes.avatar} src={companyLogo} />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${firstName} ${lastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {Can({ role, perform: 'company:settings' }) && (
          <MenuItem component={RouterLink} to="/company">
            Company
          </MenuItem>
        )}
        <MenuItem component={RouterLink} to="/account">
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
